div.NodeEditContainer {
  display: flex;
  flex-direction: column;
  width: 99vw;
  height: auto;
  font-family: "DM Sans", "sans-serif";
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 10px;
}

div.NodeEditContainer div.headerTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.NodeEditContainer form{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.NodeEditContainer input{
  max-width: 30vw;
}

div.NodeEditContainer button{
  width: fit-content;
}