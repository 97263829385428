div.dashBoardcontainer{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  height: 99vh;
  width: 99vw;
  font-family: 'DM Sans', 'sans-serif';
  gap: 10px;
  margin: 8px 8px;
}

table.usercontrol td{
  vertical-align:top
}