div.containerHome{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  min-height: 400px;
  margin-bottom: 56px;
  clear: both;
  margin-left: 8px;
  margin-right: 8px;
  
}

@media only screen and (min-width: 350px) and (max-width: 768px) {
  div.subcontainerHome{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5vh;
    justify-content:center;
  }
}


div.subcontainerHome{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5vh;

}

h1.line{
  /* border-bottom-style: groove; */
  padding-bottom: 1vh;
  background-color: #efefef;
  text-align: center;
  padding: 10px 0px;
  margin-left: 0;
  color: #545454;
}

.footer {
  background-color: gray;
  text-align: center;
  margin-top: auto;
  padding: 8px;
}