div.PostPgContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 99vh;
  width: 99vw;
  font-family: 'DM Sans', 'sans-serif';
  margin-top: 8px;
  margin-bottom: 30px;
  margin-left: 8px;
  margin-right: 8px;
}