/* the main container */
div.eduTemplateContainer{
  display: flex;
  flex-direction: column;
}


h1.eduTemplateH1{
  text-align: center;
  padding: 10px 0px;
  background: #efefef;
  margin-left: 0%;
  color : #545454;
  margin-bottom: 3vh;
  margin-top: 0;
}


div.eduTemplateHTMLContainer {
  font: 15px "DM Sans";
  padding: 0 10px;
  min-height: 400px;
  margin-bottom: 100px;
  clear: both;
  color : #545454;
}

/* create rounded border for the subheading */
span.eduTemplateHTMLContainer{
  border-radius: 15px;
  background-color: #efefef;
  padding: 8px 15px;
  font-size: medium;
  font-weight: bold;
  border-style:solid;
  display: inline-block;
  margin-bottom: 1vh;
}

/* for holding anchor a tag at top */
div.caq{
  background-color: white;
  display: flex;
  gap:8px;
  align-items:center;
  margin-bottom: 7px;
  border-radius: 15px;
}

/* for commonly asked qn sections at top */
span.plus{
  background-color: black;
  color: white;
  font-size: 35px;
  border-radius: 15px 0px 0px 15px;
}


/* create rounded corner white blank for content */
div.contentP{
  background-color: white;
  border-radius: 15px;
  padding: 3px 5px;
  margin-bottom: 2vh;
  scroll-margin-top: 120px;
}



img {
  max-width: 100%;
  object-fit: contain;
}

p.p1image {
  text-align: center;
}

span.imgcredits {
  font-size: 10px;
  font-style: italic;
  color: #555555;
}

span.imgcredits > a {
  color: #555555;
}