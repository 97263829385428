div.editContainer {
  display: flex;
  flex-direction: column;
  width: 99vw;
  height: auto;
  font-family: "DM Sans", "sans-serif";
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 10px;
}

div.editContainer div.headerTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.editContainer div.editorpreview {
  display: flex;
}

div.editContainer div.editor {
  width: 50%;

}

div.editContainer div.preview {
  width: 50%;
}

div.editContainer div.editorpreview div.editor{
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.editContainer div.editorpreview div.editor Textarea{
  width:90%;
  display: block;
}

div.editContainer div.editorpreview div.editor form{
  width:100%;

}




div.editorpreview div.preview div.eduTemplateHTMLContainer {
  font: 15px "DM Sans";
  padding: 0 10px;
  min-height: 400px;
  margin-bottom: 100px;
  clear: both;
  color : #545454;
}

div.editorpreview div.preview h1.eduTemplateH1{
  text-align: center;
  padding: 10px 0px;
  background: #efefef;
  margin-left: 0%;
  color : #545454;
  margin-bottom: 3vh;
  margin-top: 0;
}

/* create rounded border for the subheading */
div.editorpreview div.preview span.eduTemplateHTMLContainer{
  border-radius: 15px;
  background-color: #efefef;
  padding: 8px 15px;
  font-size: medium;
  font-weight: bold;
  border-style:solid;
  display: inline-block;
  margin-bottom: 1vh;
}

/* for holding anchor a tag at top */
div.editorpreview div.preview div.caq{
  background-color: white;
  display: flex;
  gap:8px;
  align-items:center;
  margin-bottom: 7px;
}

/* for commonly asked qn sections at top */
div.editorpreview div.preview span.plus{
  background-color: black;
  color: white;
  font-size: 35px;
  border-radius: 15px 0px 0px 15px;
}


/* create rounded corner white blank for content */
div.editorpreview div.preview div.contentP{
  background-color: white;
  border-radius: 15px;
  padding: 3px 5px;
  margin-bottom: 2vh;
  scroll-margin-top: 120px;
}

div.editorpreview label{
  margin-right: 6px;
  margin-bottom: 4px;
}

div.editorpreview div.preview img {
  max-width: 100%;
  object-fit: contain;
}

div.editorpreview div.preview p.p1image {
  text-align: center;
}

div.editorpreview div.preview span.imgcredits {
  font-size: 10px;
  font-style: italic;
  color: #555555;
}

div.editorpreview div.preview span.imgcredits > a {
  color: #555555;
}