@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


body {
  background-color: #f1ead8;
  margin: 0;
  font-family: 'DM Sans', 'sans-serif';
}

:root{
  --text-color:#545454
}