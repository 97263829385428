div.dialogmap{
  display: flex;
  max-width: 5000px;
  max-height: 5000px;
  font-family: 'DM Sans', 'sans-serif';
  margin: 8px 8px;
}

div.dialogmap .node circle, .node rect {
	fill: white;
	stroke: black;
}

div.dialogmap path.link{
  fill:none;
  stroke: cyan;
}