figure {
  display: inline-block;
  margin: 10px; /* adjust as needed */
}

/* figure img {
  vertical-align: top;
  border-radius: 50%;
  background-color: gray;
} */

div.circletag {
  display: flex;
  width: 18vh;
  height: 18vh;
  background: #949286;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  flex-wrap: wrap;
}

figure figcaption.Coaster{
  margin-top: 2px;
  text-align: center;
  /* border-radius: 8px; */
  /* border: 2px solid; */
  word-wrap: break-word;
  /* font-size: medium; */
  /* max-width: 18vh; */
}


div.circletag>img {
 max-height: 70%;
 max-width: 70%;
 object-fit: contain;
}

span.coasterBtn{
  max-width: 18vw;
  /* word-wrap: break-word; */
}