table.pgview {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 30%;
}

table.pgview td, table.chatlog th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

table.pgview tr:nth-child(even) {
  background-color: #D6EEEE;
}