div.kitcontainer {
  display: flex;
  justify-content: start;
  height: calc(100vh - 50px);
  font: 15px "Helvetica";
}

.TB {
  border-radius: 50%;
  background-color: #d8d8d8;
  color: black;
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
}


/* css related to react chatbot kit */
.react-chatbot-kit-chat-container {
  margin-left: 2.5vw;
  width: 90vw;
  height: 80vh;
}

.react-chatbot-kit-chat-message-container {
  flex: 1;
  height: 69vh;
  overflow: auto;
  overflow-x: hidden;
}

/* bubble text box */
.react-chatbot-kit-user-chat-message {
  max-width: 60%;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  text-align: justify;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 10px;
  max-width: 60%;
  width: fit-content;
  overflow-wrap: break-word;
  text-align: justify;
}

.rootWidget{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.react-chatbot-kit-chat-inner-container{
  height: 79vh;
}