div.menudrop {
  color: var(--text-color);
  position: absolute;
  right:5px;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
}

