div.postlist{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 99vw;
  /* height: 99vh; */
  font-family: 'DM Sans', 'sans-serif';
  gap: 10px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 30px;
  height:auto;
}

table.adminEduCont {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 95%;
}

table.adminEduCont td, table.adminEduCont th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

table.adminEduCont tr:nth-child(even) {
  background-color: #D6EEEE;
}

div.postcud div.rowControl{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}