table.filteredChatlog {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table.filteredChatlog td, table.filteredChatlog th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

table.filteredChatlog tr:nth-child(even) {
  background-color: #D6EEEE;
}

div.rowNo label{
  margin-right: 1vw;
}

div.rowNo input{
  width: 50px;
}

select.filteredChatlog{
  width: fit-content;
}