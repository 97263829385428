.header {
  background-color: white;
  display: flex;
  flex: 1 1 0%;
  border: bottom width 1px;
}

h1.headerH1 {
  /* font-size: 2vw; */
  font-size: clamp(25px,2vw,150px);
  font-weight: 500;
  color: #545454;
  margin-left: 2vw;
  font-family: "DM Sans", Helvetica, sans-serif;
  flex-grow: 1;
}

div.menuButton{
  position: relative;
}